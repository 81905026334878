import Home from "./view/home/Home";
import "./App.css"
function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
