const Aloqa = () => {

    return (
        <div className="aloqa py-3 bg-light">
            <h5>Боғланиш учун</h5>
            <p>+998 73 244 05 35</p>
            <p>+998 73 244 35 05</p>

            <a className="oq-button bg-white" href='https://t.me/FCBuznews'><i className="bi bi-telegram text-primary"></i>@t.me/pagerhelp</a>
        </div>
    );
}

export default Aloqa;