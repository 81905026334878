const Xodimlar = (props) => {

    return (
        <div>
            <div className="workCount">{props.count}</div>
            <h1>{props.fish}</h1>
        </div>
    );
}

export default Xodimlar;